import React, { useState } from "react";
import useAuthentication from "@hooks/_General/useAuthentication";
import { Box, Card, Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "@hooks/SnackbarProvider";

import BasicDatePicker from "@components/Calendar/BasicDatePicker";

import useSetTitle from "@hooks/_General/useSetTitle";
import dayjs from "dayjs";

export default function Safety() {
  const { userData } = useAuthentication();
  const showSnackbar = useSnackbar();

  // States for user data
  const [date, setDate] = useState(dayjs());

  useSetTitle("Safety");
  const { query } = useAuthentication();

  const handleClick = async (injury) => {
    const isDevEmail = ["jwindhorst@fshac.com", "cheiser@fshac.com"].includes(
      userData.email
    );
    const email = isDevEmail ? "NOSALESMANASSIGNED@FSHAC.COM" : userData.email;

    const data = {
      email: email,
      date: date.format("YYYY-MM-DD"),
      injury: injury,
    };

    try {
      const response = await query.post("/service/safety-log/create", data);

      const severity = response.data[0].saved === false ? "error" : "success";
      showSnackbar(response.data[0].message, severity);
    } catch (error) {
      showSnackbar(
        "There was a problem with your request, please try again later.",
        "error"
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Date selector here */}
        <Card>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={10}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Injury Report Safety Log
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Daily Employee Safety Log for Injuries
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box>
                <BasicDatePicker
                  label="Report Date"
                  onChange={(newValue) => setDate(newValue)}
                  shouldDisableDate={(date) => {
                    // Only allow today and yesterday
                    const today = dayjs();
                    const yesterday = today.subtract(2, "day");
                    return date.isAfter(today) || date.isBefore(yesterday);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={{ minHeight: 400 }}>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClick(false)}
                sx={{ width: "100%" }}
              >
                I was not injured
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" mb={2}>
                By clicking the 'I was not injured' button below, I am stating
                to the best of my knowledge, I did NOT have an accident or
                sustain an injury at work today. My employer and I recognize
                there may be injuries that can occur, but would not become known
                to me immediately. If I suffer any injury, I will immediately
                report it to my employer upon becoming aware of it. My employer
                and I also recognize and understand that by signing this form, I
                am not prevented from reporting an accident or inury or making a
                claim at a later time.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Con la firma de mi nombre a continuacion, estoy diciendo a lo
                mejor de mi conocimiento, no hice un accidente o sostener un
                lesion en el trabajo de hoy. Mi empleador y reconocen puede
                haber lesiones que pueden ocurrir, pero no se conoceria me
                inmediatamente. Si sufro cualquier lesion, lo hare
                inmediatamente informar a mi empleador cuando sea consciente de
                ello. Mi empleador y yo tambien reconocer y comprender que al
                firmar este formulario, no estoy impidio informar de un
                accidente o lesion o hacer una reclamacion en un momento
                posterior.
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={{ minHeight: 400 }}>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClick(true)}
                sx={{ width: "100%" }}
              >
                I was injured
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" mb={2}>
                By clicking the 'I was injured' button below, I am stating I was
                injured on the job today. If you were injured today, you must
                pick up the "injured Employee's Accident Report" from the
                manager in charge and complete the accident investigation before
                you leave the job site today.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Mediante la firma de mi nombre, a continuacion estoy afirmando
                que estaba herido hoy en el trabajo. Si usted resultaron heridos
                hoy, debe recoger el "informe de accidente del empleado Injured"
                desde el adminisrador responsable y del accidente antes de
                abandonar el lugar de trabajo hoy.
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
