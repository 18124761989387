import { Box, ImageList, ImageListItem, Skeleton } from "@mui/material";
import React, { useState, useMemo } from "react";
import ImageViewerDialog from "./ImageViewerDialog";
import _ from "lodash";

const ImageListCustom = ({ params }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  // Track loading status for each image (keyed by index)
  const [loaded, setLoaded] = useState({});

  const imageUrls = useMemo(() => _.get(params, "value", []), [params]);

  const handleImageClick = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    setDialogOpen(true);
    setSelectedImage(index);
  };

  const handleImageLoad = (index) => {
    setLoaded((prev) => ({ ...prev, [index]: true }));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <ImageList
          sx={{
            width: "100%",
            height: 184,
            overflowX: "auto",
            gridAutoFlow: "column",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(164px, 164px)) !important",
            gridAutoColumns: "minmax(164px, 164px)",
            alignItems: "center",
          }}
          cols={6}
          rowHeight={164}
        >
          {imageUrls.map((imageUrl, index) => (
            <ImageListItem key={index} sx={{ position: "relative" }}>
              {/* Show a skeleton placeholder until the image loads */}
              {!loaded[index] && (
                <Skeleton
                  variant="rectangular"
                  width={164}
                  height={164}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
              )}
              <img
                src={imageUrl}
                alt={`Install Image ${index + 1} - small`}
                loading="lazy"
                onClick={(e) => handleImageClick(e, index)}
                onLoad={() => handleImageLoad(index)}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "164px",
                  objectFit: "contain",
                  userSelect: "none",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      <ImageViewerDialog
        images={imageUrls}
        open={dialogOpen}
        onClose={handleDialogClose}
        selectedImage={selectedImage}
      />
    </>
  );
};

export default ImageListCustom;
