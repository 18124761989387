import PropTypes from "prop-types";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const BasicDatePicker = ({
  defaultValue = null,
  value,
  onChange,
  label = "Select a Date",
  disabled = false,
  shouldDisableDate = () => false,
  size = "small",
  fullWidth = true,
  margin = "none",
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        defaultValue={defaultValue || dayjs()}
        shouldDisableDate={shouldDisableDate}
        onChange={onChange}
        disabled={disabled}
        slotProps={{
          textField: {
            size: size,
            fullWidth: fullWidth,
            margin: margin,
          },
        }}
      />
    </LocalizationProvider>
  );
};

BasicDatePicker.propTypes = {
  /**
   * The default date value when no value is provided.
   * Should be compatible with Day.js date objects.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  /**
   * The selected date value.
   * Should be compatible with Day.js date objects.
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  /**
   * Callback function triggered when the date changes.
   * Receives the new date value as an argument.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The label displayed above the date picker.
   * Defaults to "Select a Date".
   */
  label: PropTypes.string,

  /**
   * Determines if the date picker is disabled.
   * Defaults to `false`.
   */
  disabled: PropTypes.bool,

  /**
   * Function to determine if a date should be disabled.
   * Receives the date as an argument and should return a boolean.
   */
  shouldDisableDate: PropTypes.func,

  /**
   * Size of the text field. Options: `"small"`, `"medium"`.
   * Defaults to `"small"`.
   */
  size: PropTypes.oneOf(["small", "medium"]),

  /**
   * Whether the text field should take up the full width of its container.
   * Defaults to `true`.
   */
  fullWidth: PropTypes.bool,

  /**
   * Determines the margin around the text field.
   * Options: `"none"`, `"dense"`, `"normal"`.
   * Defaults to `"normal"`.
   */
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
};

export default BasicDatePicker;
