import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Carousel } from "antd";
import {
  ArrowLeft,
  ArrowRight,
  Close,
  Download,
  OpenInNew,
} from "@mui/icons-material";
import "./style.css";
import { useSnackbar } from "@hooks/SnackbarProvider";

const ImageViewerDialog = ({ images, open, onClose, selectedImage = 0 }) => {
  const carouselRef = useRef(null);
  const showSnackbar = useSnackbar();
  const [internalOpen, setInternalOpen] = useState(open);
  const [currentSlide, setCurrentSlide] = useState(0);

  const isLastSlide = currentSlide === images.length - 1;
  const isFirstSlide = currentSlide === 0;

  const handleClose = () => {
    setInternalOpen(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const downloadImage = async () => {
    const imageUrl = images[currentSlide];
    try {
      // Remove any existing parameters from the URL
      const urlWithoutParams = imageUrl.split("?")[0];

      // Fetch the image data as a blob
      const response = await fetch(urlWithoutParams);
      const blob = await response.blob();

      // Determine the file extension from the Content-Type header
      const contentType = response.headers.get("Content-Type") || "";
      let extension = "jpg"; // Default extension

      if (contentType) {
        const mimeType = contentType.split("/")[1] || "bin";
        extension = mimeType === "xml" ? "pdf" : mimeType;
      }

      // Create a blob URL
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger download
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `Install-Image-${currentSlide + 1}.${extension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Clean up the blob URL after download
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const openImageInNewTab = () => {
    const imageUrl = images[currentSlide];

    if (!imageUrl) {
      console.error("No image URL provided.");
      showSnackbar("No image URL provided.", "error");
      return;
    }

    // Remove any existing parameters from the URL
    const urlWithoutParams = imageUrl.split("?")[0];

    if (urlWithoutParams) {
      window.open(urlWithoutParams, "_blank", "noopener,noreferrer");
    }
  };

  useEffect(() => {
    setInternalOpen(open);
    if (open) {
      const selectedIndex = selectedImage || 0;
      setCurrentSlide(selectedIndex);
    } else {
      setCurrentSlide(0);
    }
  }, [open]);

  useEffect(() => {
    if (internalOpen) {
      const selectedIndex = selectedImage || 0;
      setCurrentSlide(selectedIndex);

      // Wait until Carousel has mounted
      setTimeout(() => {
        if (carouselRef.current) {
          carouselRef.current.goTo(selectedIndex, false);
        }
      }, 0);
    }
  }, [internalOpen, selectedImage]);

  return (
    <Dialog open={internalOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            Viewing Image {currentSlide + 1} of {images.length}
          </span>
          <IconButton onClick={handleClose} size="small">
            <Close fontSize="small" color="primary" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Carousel
          ref={carouselRef}
          arrows
          infinite={false}
          afterChange={(i) => setCurrentSlide(i)}
        >
          {images.map((imageUrl, index) => (
            <div key={index}>
              <img
                src={imageUrl} // use the full image blob URL
                alt={`Install Image ${index + 1}`}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "65vh", // or any value you want
                  objectFit: "contain",
                  userSelect: "none", // disable text/image selection
                }}
              />
            </div>
          ))}
        </Carousel>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ArrowLeft />}
          sx={{
            width: "150px",
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
          disabled={isFirstSlide}
          onClick={() => {
            if (carouselRef.current) {
              carouselRef.current.prev();
            }
          }}
        >
          Previous
        </Button>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="Download the image file">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Download />}
              onClick={downloadImage}
            >
              Download
            </Button>
          </Tooltip>

          <Tooltip title="Open image in a new browser tab">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<OpenInNew />}
              onClick={openImageInNewTab}
            >
              Open
            </Button>
          </Tooltip>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<ArrowRight />}
          sx={{
            width: "150px",
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
          disabled={isLastSlide}
          onClick={() => {
            if (carouselRef.current) {
              carouselRef.current.next();
            }
          }}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageViewerDialog;
