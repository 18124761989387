import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
  ImageList,
  ImageListItem,
} from "@mui/material";
import useSetTitle from "@hooks/_General/useSetTitle";
import { useSnackbar } from "@hooks/SnackbarProvider";
import useInstallImagesInfo from "@hooks/InstPic/useInstallImagesInfo";
import CardMainPage from "@components/layouts/CardMainPage";
import { Search } from "@mui/icons-material";
import { DataGridPremium, GridOverlay } from "@mui/x-data-grid-premium";
import { Carousel } from "antd";
import ImageViewerDialog from "./components/ImageViewerDialog";
import _ from "lodash";
import ImageListCustom from "./components/ImageListCustom";
import SearchIcon from "@mui/icons-material/Search";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"; // box-like icon
import CustomNoRowsOverlay from "./components/CustomNoRowsOverlay";

const InstallImages = () => {
  useSetTitle("Upcoming Install Picture LookUp");
  const showSnackbar = useSnackbar();
  const { queryDirectory, data, error, loading } = useInstallImagesInfo();
  const [customerNumber, setCustomerNumber] = useState("");
  const [inputError, setInputError] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomerNumber(value);

    // Validation: must be digits only, and 7–8 digits in length
    const isValid = /^\d{7,8}$/.test(value);
    setInputError(value.length > 0 && !isValid);
  };
  const columns = [
    {
      field: "order_number",
      headerName: "Order Number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "image_paths",
      headerName: "Install Images",
      flex: 4,
      minWidth: 150,
      renderCell: (params) => <ImageListCustom params={params} />,
    },
  ];

  const handleSearch = async (e) => {
    e.preventDefault();
    await queryDirectory(customerNumber);
  };

  useEffect(() => {
    if (error) {
      showSnackbar(
        `Failed to fetch install images for customer '${customerNumber}'`,
        "error"
      );
      // Reset the states
      setInputError(false);
    }
  }, [error]);

  return (
    <CardMainPage
      title="Upcoming Install Job Pictures Lookup"
      subheader="Find the Order Images for Customer Installs"
      sx={{
        height: {
          xs: "91%",
          sm: "90%",
          md: "100%",
        },
      }}
      searchComponent={
        <CardContent sx={{ pb: 0 }}>
          <Grid container spacing={2} component="form" onSubmit={handleSearch}>
            <Grid item xs={12} sm={4}>
              {/** Must be between 7 and 8 digits */}
              <TextField
                fullWidth
                label="Search by Customer Number"
                variant="outlined"
                value={customerNumber}
                onChange={handleChange}
                size="small"
                error={inputError}
                helperText={
                  inputError ? "Customer number must be 7 or 8 digits" : " "
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.target.blur(); // Remove focus to dismiss mobile keyboard
                    handleSearch(e); // Trigger search
                  }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      }
    >
      <DataGridPremium
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
        loading={loading}
        getRowHeight={() => "auto"}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              customerNumber={customerNumber}
              loading={loading}
            />
          ),
        }}
      />
    </CardMainPage>
  );
};

export default InstallImages;
