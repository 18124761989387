import { Navigate } from "react-router-dom";
import {
  AcademicCapIcon,
  WrenchIcon,
  BuildingLibraryIcon,
  CurrencyDollarIcon,
  PhoneIcon,
  PhotoIcon,
  QueueListIcon,
  ClipboardDocumentCheckIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/outline";
import { HealthAndSafety } from "@mui/icons-material/";
//
// Icons Link: https://unpkg.com/browse/@heroicons/react@2.2.0/24/outline/
//
// Report Card
import CallCreation from "../pages/Admin/CallCreation";
import AdminContext from "@contexts/AdminContext";
import InstallImages from "@pages/InstPic";
import Resources from "@pages/Resources/Resources";
import Sales from "@pages/Sales/Sales";
import Service from "@pages/Service/Service";
import DuctCleaning from "@pages/DuctCleaning/DuctCleaning";
import Install from "@pages/Install/Install";
import TrainingLibrary from "@pages/TrainingLibrary/TrainingLibrary";
// import Plumbing from "@pages/Install/Plumbing";
import Safety from "@pages/Safety";

/**
 * Represents a navigation child route with a specific path, component to render, and an optional icon.
 * @typedef {Object} NavigationChild
 * @property {string} path - The path of the child route.
 * @property {JSX.Element} Element - The main component for the child route, rendered as a JSX element.
 * @property {JSX.Element} [Icon] - The icon for the child route, rendered as a JSX element (optional).
 */

/**
 * Represents the root configuration of a navigation route, including its path, main component, optional context provider, and optional icon.
 * @typedef {Object} NavigationRoot
 * @property {string} path - The path of the root route.
 * @property {React.ComponentType} [Context] - The context provider component for the root route (optional).
 * @property {JSX.Element} Element - The main component for the root route, rendered as a JSX element.
 * @property {JSX.Element} [Icon] - The icon for the root route, rendered as a JSX element (optional).
 */

/**
 * Describes a complete navigation route, including its group, root configuration, and any child routes.
 * @typedef {Object} NavigationRoute
 * @property {string|string[]} group - The group(s) associated with the route, used for grouping or permissions.
 * @property {NavigationRoot} root - The root configuration of the route.
 * @property {NavigationChild[]} [children] - The child routes of the current route (optional).
 */

/**
 * Defines the structure for the application's navigation routes, where each key is a route name mapping to its configuration.
 * @type {{ [key: string]: NavigationRoute }}
 */
export const navigationRoutes = {
  default: {
    path: "/",
    direct: "/login",
    logout: "/logout",
    Element: <Navigate to="/login" replace />,
    adminGroup: "App_VAIExplorer_Admin",
  },

  Resources: {
    groups: null,
    path: "/resource",
    text: "Resources",
    Element: Resources,
    Icon: BuildingLibraryIcon,
    children: [
      // Add child for dashboard pointed to element resources
      {
        groups: null,
        path: "/dashboard",
        text: "Dashboard",
        Element: Resources,
        Icon: QueueListIcon,
      },
      {
        groups: null,
        path: "/sales",
        text: "Sales",
        Element: Sales,
        Icon: CurrencyDollarIcon,
      },
      {
        groups: null,
        path: "/install",
        text: "Install",
        Element: Install,
        Icon: BuildingStorefrontIcon,
      },
      {
        groups: null,
        path: "/service",
        text: "Service",
        Element: Service,
        Icon: ClipboardDocumentCheckIcon,
      },
      {
        groups: null,
        path: "/duct-cleaning",
        text: "Duct Cleaning",
        Element: DuctCleaning,
        Icon: ClipboardDocumentCheckIcon,
      },
      {
        groups: null,
        path: "/training-library",
        text: "Training Library",
        Element: TrainingLibrary,
        Icon: AcademicCapIcon,
      },
      // {
      //   groups: null,
      //   path: "/resource/plumbing",
      //   text: "Plumbing",
      //   Element: Plumbing,
      //   Icon: WrenchIcon,
      // },
    ],
  },

  callCreation: {
    groups: ["App_VAIExplorer_Admin"],
    Context: AdminContext,
    path: "/call-creation",
    text: "Call Creation",
    Element: CallCreation,
    Icon: PhoneIcon,
  },

  safety: {
    groups: null,
    // Context: AdminContext,
    path: "/service/safety",
    text: "Safety",
    Element: Safety,
    Icon: HealthAndSafety,
  },

  instPicLookUp: {
    groups: null,
    Context: AdminContext,
    path: "/instpic/install-images",
    text: "Install Picture Lookup",
    Element: InstallImages,
    Icon: PhotoIcon,
  },
};

export default navigationRoutes;
