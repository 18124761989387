import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { Link } from "react-router-dom";
import useSetTitle from "@hooks/_General/useSetTitle";
import CircleIcon from "@mui/icons-material/Circle";
import ResourcesListComponent from "@pages/Resources/Resources/components/ResourceListComponent";
import { serviceLinks } from "@pages/Resources/components/LinkLists";
import { serviceVideoLinks } from "@pages/Resources/components/LinkLists";

export default function Service() {
  useSetTitle("Service");

  return (
    <Card>
      <CardHeader title="Service Links" action={<></>} />
      <CardContent
        key={1}
        sx={{
          flex: 1, // Allow this section to grow and take up remaining space
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ResourcesListComponent linkList={serviceLinks} />
      </CardContent>
    </Card>
  );
}
