import React from "react"; 

// Importing material UI components 
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";

import CircleIcon from '@mui/icons-material/Circle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function ResourcesListItemComponent({url, text, sub=false, target=''}) { 

  return ( 
		  // <ListItem component="a" href={url} target={target}> 
		  <ListItem component="a" href={url} target={target}> 
			  <ListItemIcon sx={{ minWidth: 16 }}> 
          { ! sub &&
			    <CircleIcon fontSize="sm" />
           ||
          <ChevronRightIcon fontSize="sm" /> 
          }
			  </ListItemIcon> 
        <ListItem>
          <Link href={url} target={target} >
            {text}
            </Link>
        </ListItem>
		  </ListItem>
    ); 
}
