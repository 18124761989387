import { useState } from "react";
import useAuthentication from "@globalHooks/useAuthentication";
import _ from "lodash";
import { useSnackbar } from "@hooks/SnackbarProvider";
import axios from "axios";

// Helper function that returns a Promise which resolves when the image loads (or errors)
const preloadImages = (urls, args) => {
  return Promise.all(
    urls.map(
      (url) =>
        new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          // Resolve on load or error to ensure the chain continues
          img.onload = () => resolve(url);
          img.onerror = () => resolve(url);
        })
    )
  );
};

const useInstallImagesInfo = () => {
  const { query } = useAuthentication();
  const showSnackbar = useSnackbar();

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Fetches and processes installation images information for a given customer number.
   *
   * This function sends a POST request to the `/instpic/search-customer` endpoint to retrieve
   * a list of orders and their associated image files. The response is then formatted
   * to include order numbers and their corresponding image thumbnails. Each item in the
   * resulting data array is assigned a unique ID.
   *
   * @param {string} customerNumber - The customer number used to query the directory. If not provided, an empty string is used.
   * @returns {Promise<void>} This function does not return a value but updates the component's state with the fetched data.
   */
  const queryDirectory = async (customerNumber) => {
    if (!customerNumber) {
      setData([]);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const { data } = await query.get("/instpic/search-customer", {
        params: {
          customer_number: customerNumber,
        },
      });

      const formattedData = data.map((item) => ({
        id: item.order_number,
        ...item,
        image_paths: item.image_paths.map(
          (path) => `${path}?quality=50&max_size=650`
        ),
      }));

      setData(formattedData);
      // Adjust 'imageUrl' to the key containing the image URL.
      const imageUrls = formattedData.flatMap((item) => item.image_paths);
      preloadImages(imageUrls);
    } catch (error) {
      console.error("Error fetching images data:", error);
      setError(error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchInstallImage = async (imagePath) => {
    if (!imagePath) {
      return {
        imagePath,
        error: true,
      };
    }

    try {
      const response = await axios.get(imagePath, null, {
        responseType: "blob",
      });

      const blob = response.data;
      const fileURL = URL.createObjectURL(blob);
      const mimeType = blob.type;

      const extension = () => {
        const type = mimeType.split("/")[1] || "bin";
        return type === "xml" ? "pdf" : type;
      };

      return {
        imagePath,
        blob,
        url: fileURL,
        type: mimeType,
        extension: extension(),
      };
    } catch (error) {
      console.error("Error fetching install image:", error);
      return { imagePath, error: true };
    }
  };

  return {
    data,
    error,
    loading,
    queryDirectory,
  };
};

export default useInstallImagesInfo;
