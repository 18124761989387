import React, { useState, useEffect } from "react";
import useCallCreation from "../../../hooks/Admin/useCallCreation";
import {
  Alert,
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";

import {
  DataGridPro,
  useGridApiRef,
  gridVisibleColumnDefinitionsSelector,
  gridExpandedSortedRowIdsSelector,
} from '@mui/x-data-grid-pro';

import { Add } from "@mui/icons-material";
import AppointmentForm from "./components/AppointmentForm";
import _ from "lodash";

import { useDemoData } from '@mui/x-data-grid-generator';

// import ScrollRestoration from "./components/ScrollRestoration";

const CallCreation = () => {
  const callCreate = useCallCreation();
  const apiRef = useGridApiRef();
  
  const [coordinates, setCoordinates] = React.useState({
    rowIndex: 0,
    colIndex: 0,
  });

  // const [querySearch, setQuerySearch] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openAppointment, setOpenAppointment] = useState(false);

  const { data, loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 100,
  });

  const [customerNumber, setCustomerNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");

  const rows = _.get(callCreate, "srchData.data", []).map((d, index) => ({
    id: index,
    ...d,
  }));

  console.log("ROWS: ", rows);

  // const columns = [
  //   { field: "CUSTOMER_NUMBER", headerName: "Cust Number", flex: 0.75 },
  //   { field: "CUSTOMER_EMAIL", headerName: "Cust Email", flex: 1 },
  //   { field: "CUSTOMER_NAME", headerName: "Cust Name", flex: 1 },
  //   { field: "CUSTOMER_ADDRESS", headerName: "Cust Address", flex: 1 },
  //   { field: "CUSTOMER_CITY", headerName: "Cust City", flex: 0.75 },
  //   { field: "CUSTOMER_PHONE", headerName: "Cust Phone #", flex: 0.75 },
  // ];
const columns = [
    { field: "CUSTOMER_NUMBER", headerName: "Cust Number", minWidth: '170' },
    { field: "CUSTOMER_EMAIL", headerName: "Cust Email", minWidth: '170'  },
    { field: "CUSTOMER_NAME", headerName: "Cust Name", minWidth: '170' },
    { field: "CUSTOMER_ADDRESS", headerName: "Cust Address", minWidth: '170' },
    { field: "CUSTOMER_CITY", headerName: "Cust City", minWidth: '170' },
    { field: "CUSTOMER_PHONE", headerName: "Cust Phone #", minWidth: '170' },
  ];
  const handleSearch = () => {
    callCreate.fetchData({
      customer_number: customerNumber,
      email,
      address,
      name,
      city,
      phone,
    });
    console.log(name);
  };

  //
  // Logic for opening AppointmentForm
  //
  const handleCellClick = (params) => {
    const rowIndex = gridExpandedSortedRowIdsSelector(apiRef).findIndex(
      (id) => id === params.id,
    );
    const colIndex = gridVisibleColumnDefinitionsSelector(apiRef).findIndex(
      (column) => column.field === params.field,
    );
    setCoordinates({ rowIndex, colIndex });
  };

  const handleRowSelection = async (data) => {
    const rowData = await rows.find((row) => row.id === data[0]);
    console.log("Selected Row: ", rowData);
    await setSelectedCustomer(rowData);
  };

  useEffect(() => {
    if (selectedCustomer) {
      setOpenAppointment(true);
    }
  }, [selectedCustomer]);

  const handleOpenAppointment = () => {
    setSelectedCustomer(null);
    setOpenAppointment(true);
  };

  const handleCloseAppointment = () => {
    setOpenAppointment(false);
  };

  // Used to clear search results
  const [clearSearch, setClearSearch] = useState(false);

  useEffect(() => {
    if (clearSearch) {
      handleSearch();
      console.log("HIIII");
      setClearSearch(false);
    }
  }, [clearSearch]);
  const handleSubmitAppointment = () => {
    // This is seperate from Cancel, it will bring use to Home of Call Creation
    // after successful Submit by clearing search results
    setOpenAppointment(false);
    setEmail("");
    setAddress("");
    setCity("");
    setName("");
    setCustomerNumber("");
    setPhone("");
    setClearSearch(true);
  };

  return (
    <Card>
      <CardHeader
        title="Call Creation"
        subheader="Find Existing Customers & Schedule Appointments. If new Customer, click 'New Customer'."
        action={
          <>
            <AppointmentForm
              open={openAppointment}
              onCancel={handleCloseAppointment}
              onSubmit={handleSubmitAppointment}
              customerData={selectedCustomer}
            />
          </>
        }
      />
      <CardContent>
        <Box sx={{
            textAlign: 'center',
            width: '100%', 
            fontWeight: "600",
            fontSize: "1.5rem", 
            display: "block",
            flex: 1,
            flexDirection: "row",
            marginBottom: '15px',
          }} >
          Search By:
        </Box>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Customer Number"
              variant="outlined"
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <TextField
              fullWidth
              label="City"
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <TextField
              fullWidth
              label="Phone #"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={1.5} textAlign="center">
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent
        sx={{
          // flex: 1, // Allow this section to grow and take up remaining space
          // display: "flex",
          // flexDirection: "column",
        }}
      >
        {callCreate.srchError ? (
          <Box textAlign="center">
            <Typography variant="h6" gutterBottom>
              Search by one of the Customer Criteria.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Select the Customer's row to schedule Appointment
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleOpenAppointment}
            >
              New Customer
            </Button>
          </Box>
        ) : (
            <Box sx={{ width: '600', overflowX: 'scroll' }}>
              <Box sx={{ height: 400 }}>
                <DataGridPro
                  apiRef={apiRef}
                  onCellClick={handleCellClick}
                  hideFooter
                  columns={columns}
                  rows={rows} 
                  onRowSelectionModelChange={(data) => handleRowSelection(data)}
                  initialState={{
                    ...data.initialState,
                    scroll: { top: 1000, left: 1000 },
                  }} 
                />
              </Box>
            </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CallCreation;
