import React from "react";

// Importing material UI components 
import {
    List,
    Grid,

} from "@mui/material";

import ResourceListItem from "./ResourceListItemComponent";

export default function ResourcesListComponent({linkList}) {

    return (
        <Grid container spacing={1} justifyContent="left">
            <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
            <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                {linkList.map((item, i) => (
                    <ResourceListItem url={item.url} text={item.text} target={item.target} key={i} />
                ))}
            </List>
        </Grid>
    );
}
