import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Synchrony
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;
              console.log(email);
              handleClose();
            },
          },
        }}
      >
        <DialogTitle>
          Expand your purchasing power with Promotional Financing through
          Synchrony.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Synchrony is one of the nation's premier consumer financial services
            companies. Our roots in consumer finance trace back to 1932, and
            today we are the largest provider of private label credit cards in
            the United States based on purchase volume and receivables.
            <List>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon fontSize="sm" />
                </ListItemIcon>
                <ListItemText primary="A simple application process, and fast credit decisions" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon fontSize="sm" />
                </ListItemIcon>
                <ListItemText primary="Unsecured, revolving credit lines" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon fontSize="sm" />
                </ListItemIcon>
                <ListItemText primary="Promotional financing options *" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon fontSize="sm" />
                </ListItemIcon>
                <ListItemText primary="Convenient monthly payment options" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon fontSize="sm" />
                </ListItemIcon>
                <ListItemText primary="Online account management" />
              </ListItem>
            </List>
            <Grid className="italic text-sm">
              *Subject to credit approval. Minimum monthly payments required.
              See store for details. For all promotions: Qualifying purchase
              must be made with your Home Design credit card issued by Synchrony
              Bank and a $29 account activation fee will apply and be charged at
              the time first purchase posts to account. For new accounts:
              Purchase APR is 26.99%; Minimum Monthly Interest Charge is $2.
              Existing cardholders should see their credit card agreement for
              their applicable terms. Subject to credit approval.
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link
            // https://resources.fshac.com/mysynchrony.html
            to="https://etail.mysynchrony.com/eapply/eapply.action?mid=5348123080000049&pcgc=HY00"
            rel="noopener noreferrer"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              width: "100%",
              textAlign: "right",
            }}
          >
            <ListItemText primary="Subscribe Now" />
          </Link>

          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
