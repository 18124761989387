import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { Link } from "react-router-dom";
import useSetTitle from "@hooks/_General/useSetTitle";
import CircleIcon from '@mui/icons-material/Circle';
import ResourcesListComponent from "@pages/Resources/Resources/components/ResourceListComponent";
import { trainingLibraryLinks } from "@pages/Resources/components/LinkLists";

// const trainingLibraryLinks = [
//   { 
//     url: "http://youtu.be/fxUrNCOMzjg", 
//     text: 'Dye Test Video',
//     target: '_blank'
//   },
//   { 
//     url: "http://youtu.be/sDz92fX1g-E", 
//     text: 'Leak Check Video',
//     target: '_blank'
//   },
//   { 
//     url: "http://youtu.be/vqUGsVmiPiU", 
//     text: 'Isolation Test Video',
//     target: '_blank'
//   },
//   { 
//     url: "http://youtu.be/xvpPpTF2NaE", 
//     text: 'Compressor Check Video',
//     target: '_blank'
//   },
//   { 
//     url: "https://youtu.be/wdLR9j3Wk6M", 
//     text: 'Hot Water Tank Clean & Check',
//     target: '_blank'
//   },
//   { 
//     url: "https://www.youtube.com/watch?v=pqem6DsdKfI", 
//     text: 'Air Conditioner Maintenance',
//     target: '_blank'
//   },
//   { 
//     url: "https://youtu.be/8ZUQ0Q0Mx48", 
//     text: 'How to Clean and Check a Furnace',
//     target: '_blank'
//   },
// ];

export default function TrainingLibrary() {
  useSetTitle("Training Library");

  return (
    <>
    <Card>
      <CardHeader
        title="Training Library" 
        action={<></>}
      />
      <CardContent 
            key={1} 
            sx={{
              flex: 1, // Allow this section to grow and take up remaining space
              display: "flex",
              flexDirection: "column",
          }} >
        <ResourcesListComponent linkList={trainingLibraryLinks} />
      </CardContent>
    </Card>
    </>
  );
}
