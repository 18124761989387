import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { Link } from "react-router-dom";
import useSetTitle from "@hooks/_General/useSetTitle";
import CircleIcon from '@mui/icons-material/Circle';
import ResourcesListComponent from "@pages/Resources/Resources/components/ResourceListComponent";
import { ductCleaningLinks } from "@pages/Resources/components/LinkLists";

export default function DuctCleaning() {
  useSetTitle("Duct Cleaning");

  return (
    <>
    <Card>
      <CardHeader
        title="Duct Cleaning" 
        action={<></>}
      />
      <CardContent 
            key={1} 
            sx={{
              flex: 1, // Allow this section to grow and take up remaining space
              display: "flex",
              flexDirection: "column",
          }} >
        <ResourcesListComponent linkList={ductCleaningLinks} />
      </CardContent>
    </Card>
    </>
  );
}
