import {
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";

import useSetTitle from "@hooks/_General/useSetTitle";
import ResourcesListComponent from "@pages/Resources/Resources/components/ResourceListComponent";
import { installLinks } from "@pages/Resources/components/LinkLists";

export default function Install() {
  useSetTitle("Install");

  return (
    <>
    <Card>
      <CardHeader
        title="Install" 
        action={<></>}
      />
      <CardContent 
            key={1} 
            sx={{
              flex: 1, // Allow this section to grow and take up remaining space
              display: "flex",
              flexDirection: "column",
          }} >
        <ResourcesListComponent linkList={installLinks} />
      </CardContent>
    </Card>
    </>
  );
}
