import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";

import ResourcesListComponent from "@pages/Resources/Resources/components/ResourceListComponent";
import SynchronyDialog from './components/SynchronyDialog';
import useSetTitle from "@hooks/_General/useSetTitle";
import { salesLinks } from "@pages/Resources/components/LinkLists";

export default function Sales() {
  useSetTitle("Sales");

  return (
    <Card>
      <CardHeader
        title="Sales"
        action={<></>}
      />
      <CardContent 
            key={1} 
            sx={{
              flex: 1, // Allow this section to grow and take up remaining space
              display: "flex",
              flexDirection: "column",
          }} >

        <ResourcesListComponent linkList={salesLinks} />
        <SynchronyDialog />
      </CardContent>
    </Card>
  );
}
