import React, { useState, useEffect } from "react";

// SALES
var salesLinks = [
    // TODO
    // { url: "", text: 'Energy Savings Calculator' }, // The original form does not working, skipping for now.
    {
        url: "https://lsvtlogin.lightspeedvt.com/",
        text: 'Four Seasons University',
        target: '_blank',
    },
    {
        url:
            "https://fourseasons.jotform.com/form/92863820881163",
        text: 'Promissory Note Requests',
        target: '_blank',
    },
];

// SERVICE LINKS
const serviceLinks = [
    {
        url: "https://form.jotform.com/93084049051151",
        text: 'Service Tech Support Form',
        target: '_blank',
    },
    {
        url: "https://lsvtlogin.lightspeedvt.com/",
        text: 'Four Seasons University',
        target: '_blank',
    },
    // { url: "https://resources.fshac.com/energy-savings-calculator/index.html", text: 'Energy Savings Calculator' }, // The original form does not working, skipping for now.
    {
        url: "https://form.jotform.com/83315177256156",
        text: 'Chicago Placard Confirmation',
        target: '_blank',
    },
    {
        url: "/resource/training-library",
        text: 'Training Library'
    },
    {
        url: "https://form.jotform.com/53484613837968",
        text: 'Pricing Form',
        target: '_blank',
    },
    {
        url: "https://form.jotform.com/61175098185967",
        text: 'Tech Alert Form',
        target: '_blank',
    },
    {
        url: "https://form.jotform.com/220965512469159",
        text: "Warranty Coil Swap Approval Form",
        target: '_blank',
    },
    {
        url: "http://mytoolstoday.com/",
        text: "Online Ordering - Tool Store",
        target: '_blank',
    },
    {
        url: "https://form.jotform.com/90725488368168",
        text: "Wrong Part Delivered",
        target: '_blank',
    },
    {
        url: "https://form.jotform.com/82065564363964",
        text: "FS Purchases",
        target: '_blank',
    },
    {
        url: "https://form.jotform.com/211265131128141",
        text: "Service Tech Misc. Request",
        target: '_blank',
    },
];

// SERVICE VIDEO LINKS
const serviceVideoLinks = [

];

// INSALL LINKS
const installLinks = [
    { 
        url: "http://form.jotformpro.com/form/51665151324955", 
        text: 'A/C Cover Sold', 
        target: '_blank' 
    },
    { 
        url: "https://form.jotform.com/83315177256156", 
        text: 'Chicago Placard Confirmation', 
        target: '_blank' },
    { 
        url: "http://form.jotformpro.com/form/51664513403954", 
        text: 'Helper Evaluation', 
        target: '_blank' },
    { 
        url: "https://docs.google.com/forms/d/1SHGP40jB2vzX5jDSYtB3Vfx_phKP3ZAEXqJ4tjqB708/", 
        text: 'Pay Forms', 
        target: '_blank' },
    { 
        url: "https://lsvtlogin.lightspeedvt.com/", 
        text: 'Four Seasons University', 
        target: '_blank' }, 
    { 
        url: "http://mytoolstoday.com/", 
        text: 'Online Ordering - Tool Store', 
        target: '_blank' },
    { 
        url: "https://form.jotform.com/71284763963972", 
        text: 'Duct Cleaning Restock Form', 
        target: '_blank' },
    { 
        url: "mysignins.microsoft.com", 
        text: 'Install Pre-Job Pictures', 
        target: '_blank' },
    { 
        // TODO Checkout about this link. Update says it should got to mysignins.microsoft.com
        url: "/instpic/install-images/forgotpw.php", 
        text: 'Credentials Lookup for Pre-Job Pictures and Install Completion Form sites', 
        target: '_blank' },
    { 
        url: "https://form.jotform.com/90246223839156", 
        text: 'Install Approval Form', 
        target: '_blank' },
    { 
        url: "https://form.jotform.com/91694258500157", 
        text: 'Install Alert Form', 
        target: '_blank' },
    { 
        url: "https://form.jotform.com/202583548377162", 
        text: 'Truck Restock JotForm', 
        target: '_blank' },
    { 
        url: "https://form.jotform.com/202944111370142", 
        text: 'Truck Unload JotForm', 
        target: '_blank' },
    { 
        url: "https://form.jotform.com/82065564363964", 
        text: 'FS Purchases', 
        target: '_blank' },
    { 
        url: "https://form.jotform.com/221593726191156", 
        text: 'Install Cash/Check Payment Info', 
        target: '_blank' },
  ];

const trainingLibraryLinks = [
    { 
        url: "http://youtu.be/fxUrNCOMzjg", 
        text: 'Dye Test Video',
        target: '_blank',
    },
    { 
        url: "http://youtu.be/sDz92fX1g-E", 
        text: 'Leak Check Video',
        target: '_blank',
    },
    { 
        url: "http://youtu.be/vqUGsVmiPiU", 
        text: 'Isolation Test Video',
        target: '_blank',
    },
    { 
        url: "http://youtu.be/xvpPpTF2NaE", 
        text: 'Compressor Check Video',
        target: '_blank',
    },
    { 
        url: "https://youtu.be/wdLR9j3Wk6M", 
        text: 'Hot Water Tank Clean & Check',
        target: '_blank',
    },
    { 
        url: "https://www.youtube.com/watch?v=pqem6DsdKfI", 
        text: 'Air Conditioner Maintenance',
        target: '_blank',
    },
    { 
        url: "https://youtu.be/8ZUQ0Q0Mx48", 
        text: 'How to Clean and Check a Furnace',
        target: '_blank',
    },
  ];
  
const ductCleaningLinks = [
    { 
        url: "https://form.jotform.com/71284763963972", 
        text: 'Duct Cleaning Restock Form',
        target: '_blank'
    },
    { 
        url: "https://form.jotform.com/91884084473164", 
        text: 'Duct Cleaning Alert Form',
        target: '_blank' 
    },
];
export { 
    salesLinks, 
    serviceLinks, 
    serviceVideoLinks, 
    installLinks, 
    trainingLibraryLinks, 
    ductCleaningLinks 
};
