import React from "react";
import { Box, Typography } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-premium";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

const CustomNoRowsOverlay = ({ customerNumber, loading }) => {
  if (loading) return null;

  const icon = customerNumber ? (
    <Inventory2OutlinedIcon sx={{ fontSize: 40, color: "text.disabled" }} />
  ) : (
    <SearchIcon sx={{ fontSize: 40, color: "text.disabled" }} />
  );

  const message = customerNumber
    ? "No install jobs found for that customer."
    : "Search above to begin.";

  return (
    <GridOverlay>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 4 }}
      >
        {icon}
        <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
          {message}
        </Typography>
      </Box>
    </GridOverlay>
  );
};

export default CustomNoRowsOverlay;
