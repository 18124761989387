import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";

import useSetTitle from "@hooks/_General/useSetTitle";
import ResourcesListComponent from "./components/ResourceListComponent";

export default function Resources() {
  // const dev = true;
  useSetTitle("Resources");

  const resourceLinks = [
    {
        url: "https://www.fourseasonsheatingcooling.com/benefits/",
        text: 'Four Seasons Employee Benefits',
        target: '_blank',
    },
    {
        url: "https://fourseasons.jotform.com/form/200305592019144",
        text: 'Bedford Park Building Access Request Form',
        target: '_blank',
    },
    {
        url: "https://fourseasons.jotform.com/form/202114439065145",
        text: 'Vehicle Accident JotForm',
        target: '_blank',
    },
    {
        url: "https://pb.fshac.com/",
        text: 'FSHAC Price Book',
        target: '_blank',
    },
    {
        url: "https://fourseasons.jotform.com/form/221585336339158",
        text: 'Parts/Warehouse/Fleet Form',
        target: '_blank',
    },
 ];

  return (
    <Card>
      <CardHeader
        title="Resources"
        // subheader="Find the Order Images for Customer Installs"
        action={<></>}
      />
      <CardContent 
            key={1} 
            sx={{
              flex: 1, // Allow this section to grow and take up remaining space
              display: "flex",
              flexDirection: "column",
          }} >
        <ResourcesListComponent linkList={resourceLinks} />
      </CardContent>
    </Card>
  );
}
