import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Box,
  Grid,
} from "@mui/material";

/**
 * CardMainPage component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.title="Default Title"] - The title of the card.
 * @param {string} [props.subheader=""] - The subheader of the card.
 * @param {ReactNode} props.children - The content of the card.
 * @param {ReactNode} [props.action=null] - Optional action component to render.
 * @param {ReactNode} [props.searchComponent=null] - Optional search component or any other component to render.
 * @param {Object} [props.sx={}] - Additional styles for the Card component.
 * @param {Object} [props.contentSx={}] - Additional styles for the CardContent component.
 * @returns {JSX.Element} The CardMainPage component.
 */
const CardMainPage = ({
  title = "Default Title",
  subheader = "",
  children,
  action = null, // Optional action component to render
  searchComponent = null, // Optional search component or any other component to render
  sx = {}, // Additional styles for the Card component
  contentSx = {}, // Additional styles for the CardContent component
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        ...sx, // Allow for additional styles to be passed in
      }}
    >
      {/* Card Header */}
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={action ? 10 : 12}>
          <Typography variant="h5">{title}</Typography>
          {subheader}
        </Grid>
        {action && (
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: {
                xs: "block",
                md: "flex",
              },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {action}
          </Grid>
        )}
      </Grid>

      {/* Divider between header and content */}
      <Divider />

      {/* Optional Search Component or any additional component */}
      {searchComponent && (
        <>
          {searchComponent}
          <Divider sx={{ mx: 5 }} />
        </>
      )}

      {/* Main Content */}
      <CardContent
        sx={{
          flex: 1,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          flexGrow: 1,
          flexDirection: "column",
          overflow: "auto",
          ...contentSx,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            maxHeight: "100%",
          }}
        >
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardMainPage;
